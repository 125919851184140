import React from 'react'
import moment from 'moment'
import Swal from 'sweetalert2'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class LicenceReplacement extends React.Component {
  // title = "Driver's Licence Replacement / Duplicate"
  resource = '/driver_license_replacements'
  form_key = 'driver_license_replacement'

  licence = null
  expired = false
  recentlyPaid = false

  hooks = {
    'post:read': data => {
      if (!data?.length) return

      // console.log(data);

      const latestPaidRecord = data?.findLast(record => record.payment_status)
      const diff = moment().diff(latestPaidRecord?.updated_at, 'months')
      this.recentlyPaid = diff < 6
    },
  }

  before_apply = async () => {
    try {
      const { Expiry, Type } = await this.getLicenceDetails(true)

      if (Type != "General Driver's Licence") {
        return `
          Unfortunately, at this time we are only able to process
          General Driver's Licences
        `
      } else if (
        moment().endOf('month').diff(Expiry, 'milliseconds', true) > 0
      ) {
        return {
          heading: 'Your Licence Has Already Expired',
          message: "Please apply for a driver's licence renewal instead.",
        }
      }
    } catch (err) {
      return `
        We were unable to fetch your driver's licence's details from
        the Road Traffic Department. Please try again later.
      `
    }

    if (this.recentlyPaid) {
      const { isConfirmed } = await Swal.fire({
        icon: 'warning',
        title: 'Confirm Resubmission',
        html: `
          We see that you've recently paid for a replacement;
          are you certain that you want to create a new application?
        `,
        showCancelButton: true,
        confirmButtonText: 'Resubmit',
      })

      return isConfirmed || null
    }

    return true
  }

  getLicenceDetails = async (refetch = false) => {
    try {
      if (refetch || !this.licence) {
        const url = '/drivers_licenses/fetch_rtd/' + $app.applicant.NIB
        const { data } = await $app.axios.get(url)
        this.licence = data['RTD Data']
      }

      return this.licence
    } catch (err) {
      console.error(err)
      return {
        error: 'Unable to retrieve licence details from the RTD database',
      }
    }
  }

  validate = async ({ reason, police_report }) => {
    const add = {}

    if (reason == 'Damaged') {
      add.police_report = null
    } else if (police_report.type != 'application/pdf') {
      return 'Sorry, only a PDF copy of your police report can be accepted at this time.'
    }

    return {
      ...add,
      drivers_licence_number: this.licence.TMN,
    }
  }

  fields = form => [
    {
      name: 'drivers_licence_number',
      save: true,
      hide: true,
      view: true,
      label: "Driver's Licence #",
    },
    {
      name: 'reason',
      type: 'select',
      options: ['Lost', 'Stolen', 'Damaged'],
    },
    {
      label: 'Licence Photo',
      key: f => f.image[1],
      name: 'license_photo_upload',
      type: 'file:image',
      hide: form.reason != 'Damaged',
      view: { hide: form.reason != 'Damaged' },
    },
    {
      name: 'police_report',
      key: f => f.image[0],
      type: 'file:pdf',
      hint: 'Only a scanned PDF will be accepted',
      hide: form.reason == 'Damaged',
      view: { hide: form.reason == 'Damaged' },
    },
    {
      name: 'damage_acknowledgement',
      type: 'checkbox',
      hide: form.reason != 'Damaged',
      view: false,
      label: `
      <span style='color:red'>
      Please confirm that the damaged drivers licence is in your possession
      as you will be required to present the damaged licence at
      your designated pick-up location of your choice in order to collect
      the replacement.
      </span>
    `,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
