import React, { useState, useEffect, useMemo } from 'react'
import DataTable from 'react-data-table-component'
import Swal from 'sweetalert2'
import moment from 'moment'

import { Spinner } from './utils'
import { listKeys } from '@helpers/objects'
import toastr from '@modules/toastr'

export default function DispatchableRecordsTable(props) {
  const [loading, setLoading] = useState(true)
  const [records, setRecords] = useState([])
  const [clear, setClear] = useState(false)
  const [query, setQuery] = useState('')
  const [selected, setSelected] = useState([])
  const [end_date, setED] = useState(
    moment().add(1, 'days').format('YYYY-MM-DD')
  )
  const [start_date, setSD] = useState(
    moment().subtract(3, 'months').format('YYYY-MM-DD')
  )

  const { id } = props.delivery.details()

  const deliveryCount = id ? (
    <em className='ml-auto mr-4'>
      <strong>{records.filter(r => r.delivery_id == id).length}</strong> item(s)
      in batch
    </em>
  ) : null

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await $app.axios.get('/services/all_paid_forms', {
          params: {
            start_date,
            end_date,
            page: 1,
            per_page: 9999,
            service_type: props.service.type,
            service_status: 'approved',
            delivery_status: 'none',
          },
        })

        setRecords(
          data.paid_forms.filter(
            r =>
              /Collections Cent(er|re)/i.test(r.pickup_sub_location) &&
              !/collected/i.test(r.delivery_status)
          )
        )
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Failed to fetch paid requests')
      }

      setLoading(false)
    }

    fetch()
  }, [start_date, end_date])

  const updateRecords = selected => {
    const list = records.slice()
    const batchID = id

    for (let { id } of selected) {
      const index = list.findIndex(r => r.id == id)
      const did = list[index].delivery_id

      list[index].delivery_id = did ? null : batchID
    }

    setRecords(list)
  }

  const filtered = useMemo(() => {
    const { id } = props.delivery.details()

    const filtered = records.filter(r => {
      const { delivery_id: did } = r
      if (id && did && did !== id) return false
      if (!id && did) return false
      return true
    })

    if (!query) return filtered
    const q = new RegExp(query, 'i')

    const searchable = r => [
      `${r.user.first_name} ${r.user.last_name}`,
      `${r.user.last_name}, ${r.user.first_name}`,
      ...listKeys(r, 'created_at'),
    ]

    return filtered.filter(r => searchable(r).some(str => q.test(str || '')))
  }, [records, query])

  const on = {
    search: ev => setQuery(ev.target.value),
    select: state => setSelected(state.selectedRows),
    clear: () => setClear(!clear),

    toggle: async action => {
      try {
        switch (action) {
          case 'add':
            await props.delivery.add(selected)
            toastr.success('Success', 'Records successfully added to delivery')
            break
          case 'remove':
            await props.delivery.remove(selected)
            toastr.success(
              'Success',
              'Records successfully removed from delivery'
            )
            break
          case 'toggle':
            const { add, rem } = selected.reduce(
              (obj, r) => {
                obj[r.delivery_id ? 'add' : 'rem'].push(r)
                return obj
              },
              {
                add: [],
                rem: [],
              }
            )

            await props.delivery.add(add)
            await props.delivery.remove(rem)
            toastr.success('Success', 'Records successfully changed')
            break
          default:
        }

        updateRecords(selected)
        setClear(!clear)
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to edit delivery items')
      }
    },
  }

  const datatable = {
    columns: [
      {
        name: 'Name',
        selector: 'user.name',
        sortable: true,
        cell: r => (
          <span onClick={props.view(r)} data-action>
            {`${r.user.last_name}, ${r.user.first_name}`.capitalize()}
          </span>
        ),
      },
      {
        name: 'Proxy',
        selector: 'proxy',
        format: r =>
          r.proxy
            ? listKeys(r.proxy, 'first_name', 'last_name')
                .join(' ')
                .capitalize()
            : 'N/A',
      },
      {
        name: 'Date',
        selector: 'created_at',
        sortable: true,
        width: '128px',
        format: ({ created_at }) => moment(created_at).format('YYYY-MM-DD'),
        sortFunction: (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      },
      {
        name: 'Pickup Location',
        selector: 'pickup_location',
        sortable: true,
        format: r =>
          r.pickup_location
            ? `${r.pickup_sub_location}, ${r.pickup_location}`.capitalize()
            : 'N/A',
      },
      {
        name: 'Status',
        selector: 'delivery_id',
        format: props.delivery?.getStatus,
        sortable: true,
      },
    ],

    noHeader: true,
    data: filtered,
    defaultSortField: 'created_at',
    defaultSortAsc: true,
    progressPending: !!loading,
    progressComponent: <Spinner />,

    selectableRows: !!props.delivery.canEdit(),
    onSelectedRowsChange: on.select,
    clearSelectedRows: clear,
    selectableRowsHighlight: true,
  }

  const action = (() => {
    if (selected.length) {
      let text = 'Toggle Selected Records'
      let action = 'toggle'

      if (selected.every(r => !r.delivery_id)) {
        text = 'Add All To Delivery'
        action = 'add'
      } else if (selected.every(r => r.delivery_id)) {
        text = 'Remove All From Delivery'
        action = 'remove'
      }

      return (
        <button className='btn btn-primary' onClick={() => on.toggle(action)}>
          {text}
        </button>
      )
    } else {
      const click = () =>
        Swal.fire({
          title: 'Change Dates',
          showCancelButton: true,

          html: `
          <div id='__dir-check'>
            <div>
              <label name='swal-tin-input'>Start Date</label>
              <input type='date' id="swal-sd-input" value="${start_date}" class="swal2-input"/>
            </div>
            <div>
              <label name='swal-tin-input'>End Date</label>
              <input type='date' id="swal-ed-input" value="${end_date}" class="swal2-input"/>
            </div>
          </div>
        `,
          preConfirm: async () => {
            const sd = document.getElementById('swal-sd-input').value
            const ed = document.getElementById('swal-ed-input').value

            if (!sd || !ed) {
              Swal.showValidationMessage('Please select start & end dates')
              return false
            }

            if (moment(sd).isAfter(ed, 'day')) {
              Swal.showValidationMessage('Start Date cannot be after End Date')
              return false
            }

            setSD(sd)
            setED(ed)
            return true
          },
        })

      return (
        <button className='btn btn-primary' onClick={click}>
          Set Date Range
        </button>
      )
    }
  })()

  return (
    <React.Fragment>
      <div className='flex md:flex-row md:justify-between items-center mb-4'>
        <div className='form-records-table-action'>{action}</div>
        {deliveryCount}
        <div className='form-records-table-search'>
          <div className='input-group'>
            <div className='input-group-prepend'>
              <span className='input-group-text'>
                <i className='fas fa-search'></i>
              </span>
            </div>
            <input
              type='search'
              placeholder='Search...'
              value={query}
              onChange={on.search}
              className='form-control'
            />
          </div>
        </div>
      </div>

      <DataTable {...datatable} pagination striped />
    </React.Fragment>
  )
}
