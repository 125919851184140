import React from 'react'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import { OPENMAPFIELDS } from '@modules/open-street-map-field'



export default class EnvironmentClearanceSupplementaryForm extends React.Component {
  resource = '/env_clearance_supp_forms'
  form_key = 'env_clearance_supp_form'
  defaults = {}

  // Delete lines as needed
//   fee           = 99
  free          = true
//   postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  coordinates = null

  // Allow map component selected coordinates to translate to form latitude and longitude fields
  validate = async values => {
    const update = {}
    const purposeList = values.purpose_ghost.filter(x => x != 'other')
    if (values.purpose_ghost.includes('other')) {
        const otherList = values.purpose_other.map(x => x.all_purposes)
        const mergedList = purposeList.concat(otherList);
        // console.log(purposeList);
        // console.log(otherList);
        // console.log(mergedList);
        update.purpose_list = mergedList
    } else {
        update.purpose_list = purposeList
    }
    
    if(this.coordinates) {
      update.latitude = this.coordinates[0]
      update.longitude = this.coordinates[1]
    }

    return update
  }


  foremost = null

  metricRegex = /[m2]|\D/gm

  fields = form => [
    {
        heading: '<h2>PART A</h2>'
    },
    "::Section 1",
    // {
    //     name: 'location_island',
    //     label: 'Island of proposed property',
    //     options: islands
    // },
    {
        heading: `<p>Please state the Activity as well as the purpose of the project for which the Certificate of Environmental Clearance application is being submitted.</p>`,
    },
    {
        name: 'all_activities_list',
        label: 'List all the activities that apply:',
        columns: ['activity'],
        format: v => v.activity
    },
    {
        name: 'purpose_list',
        multi: true,
        label: 'Purpose',
        hint: 'Select all that apply',
        columns: ['all_purposes'],
        view: {
          format: list => list.map(row => ({all_purposes: row}))
        },
        save: true,
        hide: true,
    },
    {
        name: 'purpose_ghost',
        hint: 'Select all that apply',
        multi: true,
        options: [
            'To repair, perform maintenance, or conduct a minor alteration of an existing public or private structure',
            `Minor changes to existing public or private structures, facilities, mechanical equipment, or topographical 
            features that do not result in any changes to them`,
            'The construction of a residential dwelling (single family), not exceeding a six multi-family dwelling',
            'Abandonment/Decommissioning',
            'New large scale commercial development',
            {label: 'Other', value: 'other'}
        ]
    },
    {
        name: 'purpose_other',
        label: 'Other Purpose',
        columns: ['all_purposes'],
        hide: !form.purpose_ghost?.includes('other'),
        view: false
    },
    `::Is the project located in an environmentally sensitive area as defined by Environmental Planning and Protection 
    (Amendment) Act, 2024, i.e. “environmentally sensitive area means an area (a) in which plant or animal life or 
    their habitats are either rare or especially valuable because of their special nature or role in any ecosystem and 
    which may be easily disturbed or degraded by human activities and developments; and (b) designed under section 
    23;`,
    {
        name: 'environmentally_sensitive_area',
        label: `Is the project located in an environmentally sensitive area as defined by Environmental Planning and Protection 
            (Amendment) Act, 2024`,
        type: 'select:bool',
    },
    {
        name: 'project_category',
        label: 'Select a category that Certificate of Environmental Clearance (CEC) applies to',
        options: ['New', 'Modification', 'Abandonment', 'Decommissioning']
    },


    "::SPACER",
    "::General Information",
    //Section 2
    {
        name: 'postal_address'
    },
    {
        name: 'street_name',
        label: 'Street name and Lot number',
        type: 'textarea'
    },
    {
        name: 'fax_numb',
        label: 'Fax No.',
        type: 'phone'
    },

    "::SPACER",
    "::Location of proposed activity",
    ...OPENMAPFIELDS(this),
    // FIELDS TO ADD TO DB MODEL = latitude and longitude
    {
        name: 'project_island',
        label: 'Island/Constituency',
        options: islands,
    },
    {
        name: 'project_street_address',
        label: 'Street Address',
    },
    {
        name: 'project_lot_no',
        label: 'Lot No.',
    },
    {
        name: 'own_property',
        label: 'Do you own the property on which the activity is intended to be carried out?',
        type: 'select:bool',
    },
    /*{
        name: 'if_yes',
        label: 'Please attach certified copies of Proof of ownership.',
        hide: form.own_property != 'Yes'
    }, */
    {
        name: 'adjourning_owners_list',
        label: 'Names and addresses of adjourning property owners',
        fields: ['first_name', 'last_name', 'address'],
        required: false,
        // format: v => [v.first_name, v.last_name, v.address]
    },

    "::SPACER",
    "::Site description (physical setting of the proposal, both developed and undeveloped area)",
    {
        name: 'total_area_metric',
        label: 'Total area intended for the activity (ha or m2)',
        hint: '1 hectare/ha = 10000 square meters/m2',
        save: false,
        view: false,
        options: [
            { label: 'hectare/ha', value: 'ha' },
            { label: 'square meter/m2', value: 'm2' }
        ],
        callback: (v) => {
            if (form.total_area) {
                return { total_area: `${form.total_area.replaceAll(this.metricRegex, '')} ${v}` }
            }
        },
    },
    {
        name: 'total_area',
        label: `Total area intended for the activity (${form.total_area_metric})`,
        hint: `In ${form.total_area_metric}`,
        hide: !form.total_area_metric,
        view: true,
        onBlur: (v) => {
            console.log(v);
            if (form.total_area_metric && v) {
                return { total_area: `${v.replaceAll(this.metricRegex, '')} ${form.total_area_metric}` }
            }
        },
    },
    {
        name: 'coast_distance_metric',
        label: 'How far is the projected lot from the coast (m/ft)',
        hint: '1 meter/m = 3.28 foot/ft',
        save: false,
        view: false,
        options: [
            { label: 'meters/m', value: 'm' },
            { label: 'feet/ft', value: 'ft' }
        ],
        callback: (v) => {
            if (form.coast_distance) {
                return { coast_distance: `${form.coast_distance.replaceAll(this.metricRegex, '')} ${v}` }
            }
        },
    },
    {
        name: 'coast_distance',
        label: `Total area intended for the activity (${form.coast_distance_metric})`,
        hint: `In ${form.coast_distance_metric}`,
        hide: !form.coast_distance_metric,
        view: true,
        onBlur: (v) => {
            if (form.coast_distance_metric) {
                return { coast_distance: `${v.replaceAll(this.metricRegex, '')} ${form.coast_distance_metric}` }
            }
        },
    },
    {
        name: 'previous_site_use',
        label: 'Previous site land use',
        options: [
            'Residential',
            'Agricultural',
            'Commercial',
            'Industrial',
            'Forestry',
        ],
    },
    {
        name: 'present_site_use',
        label: 'Present site land use',
        options: [
            'Residential',
            'Agricultural',
            'Commercial',
            'Industrial',
            'Forestry',
        ],
    },
    {
        name: 'project_percentage',
        label: 'What percentage of the intended project area would be cleared of vegetation?',
        type: 'integer',
        view: v => `${v}%`,
    },

    "::SPACER",
    {
        name: 'prev_app',
        label: 'Previous Application for Certificate of Environmental Clearance for this site?',
        type: 'select:bool',
    },
    {
        name: 'prev_app_ref_no',
        label: 'Reference No. of most recent application',
        hide: !form.prev_app
    },
    {
        name: 'commencement_date',
        label: 'Intended commencement date',
        type: 'date'
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />

}