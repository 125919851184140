import React from 'react'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'


export default class BirthRequest extends React.Component {
  resource = '/pha_appointment_schedulings'
  form_key = 'pha_appointment_scheduling'
  defaults = {}


  // Delete lines as needed
//   fee           = 99
//   free          = true
//   postpaid      = true
  skip_location = true
  hide_buttons  = false
  has_appointment = true

  custom_actions = []

  coordinates = null

//   validate = async values => {
//     const update = {}

//     return update
//   }

  foremost = null


  fields = form => [
    "::Section 1",

    '::Applicant Information',
    {
        name: 'clinics',
        options: [
            { label: 'Princess Margaret Hospital - PMH', value: 'PMH' },
            { label: 'Other', value: 'other' },
        ]
    },
 

    '::SPACER',
    "::Uploads",
    {
        name: 'referral_letter_upload',
        label: 'Referral Letter',
        type: 'file:image',
        key: f => f.image[0],
        required: !(form.discharge_letter_upload||form.follow_up_card_upload)
    },
    {
        name: 'discharge_letter_upload',
        label: 'Discharge Letter',
        type: 'file:image',
        key: f => f.image[1],
        required: !(form.referral_letter_upload||form.follow_up_card_upload)
    },
    {
        name: 'follow_up_card_upload',
        label: 'Follow up Card',
        type: 'file:image',
        key: f => f.image[2],
        required: !(form.discharge_letter_upload||form.referral_letter_upload)
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />

}