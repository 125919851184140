import React, { useEffect, useReducer, useState } from 'react'
import HCaptcha from '@hcaptcha/react-hcaptcha';
import $ from 'jquery'
import Swal from 'sweetalert2'
// import { ValidatorForm } from 'react-form-validator-core'
// import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'

import toastr from '@modules/toastr'
// import InputField from '@shared/form-fields/input'
import ENV from '@constants/env'
// import pluralize from 'pluralize';
import CardReactFormContainer from 'card-react';
import 'card-react/lib/card.css'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

// const ensureSupportedCard = ({ cardType }) => {
//   if (['Visa', 'Mastercard'].includes(cardType.displayName)) return
//   return 'Please use either a Visa or Mastercard'
// }

const FormSelect = props => {
  const [form, setForm] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [invoiceCheck, setInvoiceCheck] = useState(false);
  // let redirectURL = window.location.href.replace("/form/pay", '/applications');
  let redirectURL = window.location.href.replace("/form/pay", '');
  const searchTerm = '/';
  const indexOfFirst = redirectURL.indexOf(searchTerm);
  const indexOfSecond = redirectURL.indexOf(searchTerm, indexOfFirst + 1);
  const indexOfThird = redirectURL.indexOf(searchTerm, indexOfSecond + 1);
  redirectURL = `${redirectURL.substring(indexOfThird)}`;

  const sandDollarEnabled = props?.service_props?.sand_dollar;

  // console.log(redirectURL);

  // console.log(props);

  useEffect(() => {
    // Check if invoice is attached to form, then check if invoice is already paid off
    const checkInvoice = async () => {
      try {
        const result = await $app.axios.get(`/check_record_invoice?invoice_ref=${props?.form?.payment_invoice}&service_type=${props?.service_class}`)
        setInvoice(result.data.invoice.res)
        // console.log(result.data)
        toastr.success('Success', 'Invoice checked successfully')
        if (result.data.invoice.res.status == 'paid') toastr.success('Success', 'Application is already paid for')
        setInvoiceCheck(true)
      } catch (err) {
        setInvoiceCheck(true)
        console.error(err)
        // toastr.error('Error', 'Unable to check invoice')
      }
    }
    if (!invoiceCheck) checkInvoice();
  }, [invoiceCheck])

  useEffect(() => {
    const popUp = async () => {
      if (!form) {
        if (sandDollarEnabled) {
          const { isConfirmed } = await Swal.fire({
            icon: 'question',
            title: 'Will you be making a Credit/Debit Card or Sand Dollar payment today?',
            showCancelButton: true,
            confirmButtonText: 'Credit/Debit Card',
            cancelButtonText: 'Sand Dollar',
            cancelButtonColor: "#bfad69",
            allowOutsideClick: false,
            footer: `
              <span class='text-sm text-gray-700'>
                ${ENV.TRANSACTION_REQUEST}
              </span>
            `,
          })
      
          if (isConfirmed) setForm('Credit/Debit')
          if (!isConfirmed) setForm('SandDollar')
        } else {
          setForm('Credit/Debit')
        }
      }
    }

    if (invoiceCheck && (invoice?.status == 'unpaid' || !invoice)) popUp();

  }, [form, invoiceCheck, invoice])

  const formReturned = () => {
    if (invoiceCheck) {
      if (invoice?.status == 'unpaid' || !invoice) {
        if (form == 'SandDollar') {
          return <SandDollarForm props={props}/>
        } else if (form == 'Credit/Debit') {
          return <CCForm props={props}/>
        } else {
          return (<div style={{textAlign: 'center'}}/>)
        }
      } else {
        return <Redirect to={redirectURL}/>
      }
    } else {
      return (<div style={{textAlign: 'center'}}>
        Checking for related invoice...
      </div>)
    }

  }

  return formReturned()
}

const SandDollarForm = ({props}) => {
  const [response, setResponse] = useState(null)

  useEffect(() => {
    const createInvoice = async () => {
      try {
        const result = await $app.axios.put(`/update_payment_sand_dollar`, {
          service_type: props.service_class,
          [props.service_key]: {
            form_num: props.form.form_num,
          },
        })
        setResponse(result.data)
        console.log(result.data)
        toastr.success('Success', 'Invoice generated successfully')
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to generate key')
      }
    }
    createInvoice()
  }, [props])

  return (
    <div style={{textAlign: 'center'}}>
      { response &&
       (<iframe  title="Kanoo Payment Gateway" scrolling='no' src={response.token_url} width="580" height="920" style={{border: 'none', width: '100%'}}/>)
      }
    </div>
  )
}

const CCForm = ({props}) => {
  const [state, setState] = useReducer(
    (state, action) => {
      const next = { ...state }
      const [field, value] = action

      if (field == 'card-name') {
        if (/[^a-z ]/i.test(value)) return next
      }

      next.values[field] = value
      next.valid[field] = !!value

      return next
    },
    {
      values: {
        'card-number': '',
        'card-amount': Number(props.fee).toFixed(2),
        'card-name': '',
        'card-cvv': '',
        'card-exp': '',
      },
      valid: {
        'card-number': false,
        'card-name': false,
        'card-cvv': false,
        'card-exp': false,
      },
    }
  )

  const [token, setToken] = useState(null);

  // console.log(props);

  // const {
  //   meta,
  //   wrapperProps,
  //   getCardImageProps,
  //   getCardNumberProps,
  //   getExpiryDateProps,
  //   getCVCProps,
  // } = usePaymentInputs({
  //   cardNumberValidator: ensureSupportedCard,
  // })

  const value = field => props[field] || state.values[field]

  const submit = async (ev) => {
    ev.preventDefault();

    if (!token) {
      toastr.error('Error', 'Please solve hCaptcha')

      return
    }

    // if (meta.error || Object.values(state.valid).includes(false)) {
    if (Object.values(state.valid).includes(false)) {
      console.log($('.sc-ifAKCX').length)
      !state.valid['card-number'] &&
        toastr.error('Error', 'Card number is invalid')
      !state.valid['card-exp'] &&
        toastr.error('Error', 'Card Expiry is invalid')
      !state.valid['card-cvv'] && toastr.error('Error', 'Card CVV is invalid')

      return
    }

    // console.log(state)

    const { isConfirmed } = await Swal.fire({
      icon: 'question',
      title: 'Are you sure you’d like to make this transaction?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      footer: `
        <span class='text-sm text-gray-700'>
          ${ENV.TRANSACTION_REQUEST}
        </span>
      `,
    })

    if (isConfirmed) props.submit(state.values)
  }

  const borderStyle = { border: '1px solid rgb(232, 232, 232)', borderRadius: '4px' }

  return (
    <div>
    <div id='card-wrapper' className='mt-2'/>
    <CardReactFormContainer

      // the id of the container element where you want to render the card element.
      // the card component can be rendered anywhere (doesn't have to be in ReactCardFormContainer).
      container="card-wrapper" // required

      // an object contain the form inputs names.
      // every input must have a unique name prop.
      formInputsNames={
        {
          number: 'card-number', // optional — default "number"
          expiry: 'card-exp',// optional — default "expiry"
          cvc: 'card-cvv', // optional — default "cvc"
          name: 'card-name' // optional - default "name"
        }
      }

      // initial values to render in the card element
      // initialValues= {
      //   {
      //     number: '4242424242424242', // optional — default •••• •••• •••• ••••
      //     cvc: '123', // optional — default •••
      //     expiry: '16/12', // optional — default ••/••
      //     name: 'Random Name' // optional — default FULL NAME
      //   }
      // }

      // the class name attribute to add to the input field and the corresponding part of the card element,
      // when the input is valid/invalid.
      classes={
        {
          valid: 'jp-card-valid', // optional — default 'jp-card-valid'
          invalid: 'jp-card-invalid' // optional — default 'jp-card-invalid'
        }
      }

      // specify whether you want to format the form inputs or not
      formatting={false} // optional - default true
    >

      <form id={props.name} className='form-group' onSubmit={submit}>
        <div className='row mt-4 mb-3'>
          <label
            htmlFor='card-amount'
            className='col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right'
          >
            Card Holder Name
          </label>
          <div className='col-lg-7 col-md-6 col-sm-6'>
            <input style={borderStyle} className='form-control' placeholder="Card Holder Name" type="text" name="card-name"
              value={value('card-name')}
              onChange={ev => setState(['card-name', ev.target.value])}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <label
            htmlFor='card-amount'
            className='col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right'
          >
            Amount
          </label>
          <div className='col-lg-7 col-md-6 col-sm-6'>
            <input
              name='card-amount'
              icon={null}
              value={value('card-amount')}
              className='form-control'
              disabled
            />
          </div>
        </div>
        <div className='row'>
          <label
            htmlFor='name'
            className='col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right'
          >
            Card <span className='required-label'></span>
          </label>
          <div className='col cc-input'>
            <div className='input-group'>
              <input placeholder="Card number" type="tel" name="card-number" maxLength={20} required
                onKeyDownCapture={ev => {
                  if (['Backspace', 'Tab'].includes(ev.key) ) return
                  if (!ev.key.match(/[0-9]/g)) return ev.preventDefault();

                  if (ev.key != 'Backspace'){
                    const val = ev.target.value.replaceAll(' ', '')
                    let isBlankSpace = ev.target.value[ev.target.value.length - 1]
                    isBlankSpace = isBlankSpace == ' '
                    if ((val.length % 4) == 0 && ev.target.value.length < 19) {
                      if (!isBlankSpace) ev.target.value += ' ';
                    }
                  }
                }}
                onKeyUpCapture={ev => {
                  // console.log(ev.key)
                  if (ev.key != 'Backspace'){
                    const val = ev.target.value.replaceAll(' ', '')
                    if ((val.length % 4) == 0 && ev.target.value.length < 19) {
                      ev.target.value += ' '
                    }
                  }
                  setState(['card-number', ev.target.value])
                }}
                onChange={ev => setState(['card-number', ev.target.value])}
                style={{border: '1px solid #ced4da', borderRadius: '4px', borderBottomRightRadius: '0', borderTopRightRadius: '0', borderRight: 'none', width: '160px'}}
                value={value('card-number')}
              />
              <input placeholder="MM/YY" type="tel" name="card-exp" maxLength={5} required
                onKeyDownCapture={ev => {
                  if (['Backspace', 'Tab'].includes(ev.key) ) return
                  if (!ev.key.match(/[0-9]/g)) return ev.preventDefault();

                  if (ev.key != 'Backspace'){
                    const checkSlash = ev.target.value.includes('/')
                    if (ev.target.value.length === 2 && !checkSlash) {
                      ev.target.value += '/'
                    }
                  }
                }}
                onKeyUpCapture={ev => {
                  // console.log(ev.key)
                  if (ev.key != 'Backspace'){
                    const checkSlash = ev.target.value.includes('/')
                    if (ev.target.value.length === 2 && !checkSlash) {
                      ev.target.value += '/'
                    }
                  }
                  setState(['card-exp', ev.target.value])
                }}
                onChange={ev => setState(['card-exp', ev.target.value])}
                style={{border: '1px solid #ced4da', borderRadius: '0', borderLeft: 'none', borderRight: 'none', width: '60px'}}
                value={value('card-exp')}
              />
              <input placeholder="CVC" type="tel" name="card-cvv" maxLength={3} required
                onKeyDownCapture={ev => {
                  if (['Backspace', 'Tab'].includes(ev.key) ) return
                  if (!ev.key.match(/[0-9]/g)) return ev.preventDefault();
                }}
                onChange={ev => setState(['card-cvv', ev.target.value])}
                style={{border: '1px solid #ced4da', borderRadius: '4px', borderBottomLeftRadius: '0', borderTopLeftRadius: '0', borderLeft: 'none', width: '40px'}}
                value={value('card-cvv')}
              />
            </div>

            <div className='flex flex-row mt-2 space-x-3'>
                <span className='text-xs text-gray-600 italic'>We Accept:</span>
                <svg width={'1.5em'} height={'1em'} viewBox={'0 0 24 16'}>
                  {images.visa}
                </svg>
                <svg width={'1.5em'} height={'1em'} viewBox={'0 0 24 16'}>
                  {images.mastercard}
                </svg>
            </div>
            <div className='mt-4'>
              <HCaptcha
                sitekey="68377ff1-2c36-4eb4-8643-9ae43e53e3ef"
                // onLoad={onLoad}
                onVerify={setToken}
                // ref={captchaRef}
              />
            </div>
          </div>
        </div>
      </form>
    </CardReactFormContainer>
    </div>
  )
}

export default FormSelect

    /* <ValidatorForm id={props.name} onSubmit={submit}>
      <div className='form-group form-show-validation row'>
        <label
          htmlFor='name'
          className='col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right'
        >
          Card holder name <span className='required-label'></span>
        </label>
        <div className='col-lg-7 col-md-6 col-sm-6'>
          <InputField
            onChange={ev => setState(['card-name', ev.target.value])}
            name='card-name'
            icon={null}
            value={value('card-name')}
            validators={['required']}
            errorMessages={['This field is required']}
            className='form-control'
            placeholder='Card Holder Name'
          />
        </div>
      </div>

      <div className='form-group form-show-validation row'>
        <label
          htmlFor='card-amount'
          className='col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right'
        >
          Amount
        </label>
        <div className='col-lg-7 col-md-6 col-sm-6'>
          <InputField
            name='card-amount'
            icon={null}
            value={value('card-amount')}
            className='form-control'
            disabled
          />
        </div>
      </div>

      <div className='form-group form-show-validation row'>
        <label
          htmlFor='name'
          className='col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right'
        >
          Card <span className='required-label'></span>
        </label>
        <div className='col cc-input'>
          <PaymentInputsWrapper {...wrapperProps}>
            <svg {...getCardImageProps({ images })} />
            <input
              {...getCardNumberProps({
                onChange: ev => setState(['card-number', ev.target.value]),
              })}
              value={value('card-number')}
            />
            <input
              {...getExpiryDateProps({
                onChange: ev => setState(['card-exp', ev.target.value]),
              })}
              value={value('card-exp')}
            />
            <input
              {...getCVCProps({
                onChange: ev => setState(['card-cvv', ev.target.value]),
              })}
              value={value('card-cvv')}
            />
          </PaymentInputsWrapper>
          <div className='flex flex-row mt-2 space-x-3'>
            <span className='text-xs text-gray-600 italic'>We Accept:</span>
            <svg width={'1.5em'} height={'1em'} viewBox={'0 0 24 16'}>
              {images.visa}
            </svg>
            <svg width={'1.5em'} height={'1em'} viewBox={'0 0 24 16'}>
              {images.mastercard}
            </svg>
          </div>
          <div className='mt-4'>
            <HCaptcha
              sitekey="68377ff1-2c36-4eb4-8643-9ae43e53e3ef"
              // onLoad={onLoad}
              onVerify={setToken}
              // ref={captchaRef}
            />
          </div>
        </div>
      </div>
    </ValidatorForm> */
