import React from 'react'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import nationalities from '@constants/nationalities'



export default class BirthRequest extends React.Component {
  resource = '/birth_requests'
  form_key = 'birth_request'
  defaults = {}

  isNewProvidence = /new-providence/.test(this.props.location.pathname)
  isGrandBahama = /grand-bahama/.test(this.props.location.pathname)


  // Delete lines as needed
//   fee           = 99
//   free          = true
//   postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  coordinates = null

  validate = async values => {
    const update = {}
    const setIsland = () => {
        if(this.isNewProvidence) { return 'New Providence' }
        if(this.isGrandBahama) { return 'Grand Bahama' }
    }

    update.island = setIsland();
    update.facility = values.facility == 'other' ? values.facility_other : values.facility;

    return update
  }

  foremost = null


  fields = form => [
    "::Section 1",

    {
        name: 'island',
        save: true,
        hide: true,
        // Set view value as string to show negative numbers
        view: {
          value: v => `${v}`
        }
    },

    '::Applicant Information',
    {
        name: 'facility',
        options: [
            { label: 'Princess Margaret Hospital - PMH', value: 'PMH' },
            { label: 'Other', value: 'other' },
        ]
    },
    {
        name: 'facility_other',
        hide: form.facility != 'other',
        save: false,
        view: false,
    },
    {
        name: 'i_am_applicant',
        label: 'I am requesting this service for myself',
        type: 'select:bool',
    },
    {
        name: 'applicant_f_name',
        label: 'First Name',
        hide: form.i_am_applicant
    },
    {
        name: 'applicant_m_name',
        label: 'Middle Name',
        required: false,
        hide: form.i_am_applicant
    },
    {
        name: 'applicant_l_name',
        label: 'Last Name',
        hide: form.i_am_applicant
    },
    {
        name: 'applicant_maiden_name',
        label: 'Maiden Name',
        required: false,
    },
    {
        name: 'applicant_marital_status',
        label: 'Marital Status',
        options: [
            'single',
            'married',
            'divorced',
            'other'
        ],
    },
    {
        name: 'applicant_nationality',
        label: 'Nationality',
        options: nationalities,
    },
    {
        name: 'applicant_dob',
        label: 'Date of Birth',
        type: 'date',
        hide: form.i_am_applicant
    },
    {
        name: 'applicant_gender',
        label: 'Gender',
        options: [
            'Male',
            'Female',
        ],
        hide: form.i_am_applicant
    },
    {
        name: 'applicant_resident',
        label: 'Resident or Non-Resident',
        options: [
            'Resident',
            'Non-Resident',
        ],
        hide: form.i_am_applicant
    },
    {
        name: 'applicant_address',
        label: 'Address',
        hide: form.i_am_applicant
    },
    {
        name: 'applicant_email',
        label: 'Email Address',
        hide: form.i_am_applicant
    },
    {
        name: 'applicant_phone',
        label: 'Phone Contact',
        type: 'phone',
        hide: form.i_am_applicant
    },
    {
        name: 'applicant_phone_2',
        label: 'Phone Contact (secondary)',
        type: 'phone',
        required: false,
        hide: form.i_am_applicant
    },
    {
        name: 'applicant_employment',
        label: 'Place of Employment',
        // hide: form.i_am_applicant
    },
    {
        name: 'applicant_insurance',
        label: 'Insurance Carrier',
        required: false,
        // hide: form.i_am_applicant
    },
    {
        name: 'applicant_passport_no',
        label: 'Passport #',
        type: 'passport',
        required: !form.applicant_voters_no,
        // hide: form.i_am_applicant
    },
    {
        name: 'applicant_nib_no',
        label: 'NIB #',
        type: 'integer',
        hide: form.i_am_applicant
    },
    {
        name: 'applicant_voters_no',
        label: 'Voters #',
        type: 'integer',
        required: !form.applicant_passport_no,
        // hide: form.i_am_applicant
    },

    '::SPACER',
    "::Mother's Information",
    {
        name: 'mother_f_name',
        label: 'First Name',
    },
    {
        name: 'mother_m_name',
        label: 'Middle Name',
        required: false,
    },
    {
        name: 'mother_l_name',
        label: 'Last Name',
    },
    {
        name: 'mother_maiden_name',
        label: 'Maiden Name',
        hint: 'If never married use same Last Name',
    },
    {
        name: 'mother_address',
        label: 'Address',
    },
    {
        name: 'mother_dob',
        label: 'Date of Birth',
        type: 'date',
    },
    {
        name: 'mother_po_box',
        label: 'PO Box',
        required: false,
    },
    {
        name: 'mother_phone',
        label: 'Phone Contact',
        type: 'phone',
    },
    {
        name: 'mother_phone_2',
        label: 'Phone Contact (secondary)',
        type: 'phone',
        required: false,
    },

    '::SPACER',
    "::Father's Information",
    {
        name: 'father_f_name',
        label: 'Last Name',
    },
    {
        name: 'father_m_name',
        label: 'Middle Name',
        required: false
    },
    {
        name: 'father_l_name',
        label: 'Last Name',
    },
    {
        name: 'father_address',
        label: 'Address',
    },
    {
        name: 'father_dob',
        label: 'Date of Birth',
        type: 'date',
    },
    {
        name: 'father_po_box',
        label: 'PO Box',
        required: false,
    },
    {
        name: 'father_phone',
        label: 'Phone Contact',
        type: 'phone',
    },
    {
        name: 'father_phone_2',
        label: 'Phone Contact (secondary)',
        type: 'phone',
        required: false,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />

}